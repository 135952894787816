import React from 'react'
import { Div } from '../../styled-components'
import ServiceImage from '../../../images/FixProvitional.svg'

const ServiceImg = () => (
  <Div
    width="500px"
    height="300px"
    width960="280px"
    height960="220px"
    background_color="#666666"
    display="flex"
    align_items="center"
    justify_content="center"
  >
    <img src={ServiceImage} alt="serviceImage" style={{width: '180px'}}/>
  </Div>
)

export default ServiceImg