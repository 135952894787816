import React, { useState } from 'react';
import styled from 'styled-components';
import { Div } from '../styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';
import ShowService from './showEachServices/showService';

const ButtonService = styled.button`
  padding: 10px 18px;
  margin-bottom: 10px;
  width: 288px;
  font-size: 18px;
  background: ${({ active }) => (active ? `#69B02A` : '#f2f2f2')};
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #69b02a;
  }
`;

const EachService = () => {
  const [preliminary, setPreliminary] = useState(true);
  const [jobsite, setJobsite] = useState(false);
  const [test, setTest] = useState(false);
  const [pneumatic, setPneumatic] = useState(false);
  const [deficiency, setDeficiency] = useState(false);
  const [sound, setSound] = useState(false);
  const [vibration, setVibration] = useState(false);
  const [smoke, setSmoke] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [fume, setFume] = useState(false);
  const [hospital, setHospital] = useState(false);
  const [duct, setDuct] = useState(false);
  const [cad, setCad] = useState(false);
  const [hvac, setHvac] = useState(false);

  const toggleFalse = () => {
    setPreliminary(false);
    setJobsite(false);
    setTest(false);
    setPneumatic(false);
    setDeficiency(false);
    setSound(false);
    setVibration(false);
    setSmoke(false);
    setKitchen(false);
    setFume(false);
    setHospital(false);
    setDuct(false);
    setCad(false);
    setHvac(false);
    if (window.innerWidth < 961) scrollTo('#scrollToService');
  };

  const handlePreliminary = (e) => {
    e.preventDefault();
    toggleFalse();
    setPreliminary(true);
  };
  const handleJobsite = (e) => {
    e.preventDefault();
    toggleFalse();
    setJobsite(true);
  };
  const handleTest = (e) => {
    e.preventDefault();
    toggleFalse();
    setTest(true);
  };
  const handlePneumatic = (e) => {
    e.preventDefault();
    toggleFalse();
    setPneumatic(true);
  };
  const handleDeficiency = (e) => {
    e.preventDefault();
    toggleFalse();
    setDeficiency(true);
  };
  const handleSound = (e) => {
    e.preventDefault();
    toggleFalse();
    setSound(true);
  };
  const handleVibration = (e) => {
    e.preventDefault();
    toggleFalse();
    setVibration(true);
  };
  const handleSmoke = (e) => {
    e.preventDefault();
    toggleFalse();
    setSmoke(true);
  };
  const handleKitchen = (e) => {
    e.preventDefault();
    toggleFalse();
    setKitchen(true);
  };
  const handleFume = (e) => {
    e.preventDefault();
    toggleFalse();
    setFume(true);
  };
  const handleHospital = (e) => {
    e.preventDefault();
    toggleFalse();
    setHospital(true);
  };
  const handleDuct = (e) => {
    e.preventDefault();
    toggleFalse();
    setDuct(true);
  };
  const handleCad = (e) => {
    e.preventDefault();
    toggleFalse();
    setCad(true);
  };
  const handleHvac = (e) => {
    e.preventDefault();
    toggleFalse();
    setHvac(true);
  };

  return (
    <Div
      padding='40px 20px'
      display='flex'
      align_items='center'
      justify_content='center'
      flexDirection960='column'
    >
      <Div display='flex' flexDirection='column'>
        <ButtonService active={preliminary} onClick={handlePreliminary}>
          Preliminary Plan Review
        </ButtonService>
        <ButtonService active={jobsite} onClick={handleJobsite}>
          Jobsite Inspections
        </ButtonService>
        <ButtonService active={test} onClick={handleTest}>
          Test & Balance of Air & Hydronics
        </ButtonService>
        <ButtonService active={pneumatic} onClick={handlePneumatic}>
          Pneumatic Controls & Calibration
        </ButtonService>
        <ButtonService active={deficiency} onClick={handleDeficiency}>
          Deficiency Reports
        </ButtonService>
        <ButtonService active={sound} onClick={handleSound}>
          Sound Testing
        </ButtonService>
        <ButtonService active={vibration} onClick={handleVibration}>
          Vibration Testing
        </ButtonService>
        <ButtonService active={smoke} onClick={handleSmoke}>
          Smoke Control Testing
        </ButtonService>
        <ButtonService active={kitchen} onClick={handleKitchen}>
          Kitchen Hood Certification
        </ButtonService>
        <ButtonService active={fume} onClick={handleFume}>
          Fume Hood Testing
        </ButtonService>
        <ButtonService active={hospital} onClick={handleHospital}>
          Hospital Critical Room Certification
        </ButtonService>
        <ButtonService active={duct} onClick={handleDuct}>
          Duct Pressure Testing
        </ButtonService>
        <ButtonService active={cad} onClick={handleCad}>
          CAD / Computer Modeling / Drawings
        </ButtonService>
        <ButtonService active={hvac} onClick={handleHvac} customPadding>
          HVAC Surveys & Phased Retrofit Pre-Construction Testing
        </ButtonService>
      </Div>
      <Div id='scrollToService'>
        {preliminary ? (
          <ShowService
            serviceTitle='Preliminary Plan Review'
            serviceDescription='A thorough examination of all specifications, submittals and plans including shop drawings to verify conformance with the project drawings and subsequent changes.'
            secondText='Each review will include a report of any issues that need clarification.'
          />
        ) : jobsite ? (
          <ShowService
            serviceTitle='Jobsite Inspections'
            serviceDescription='A field observation to determine if the HVAC system’s installation is correct and complete.'
          />
        ) : test ? (
          <ShowService
            serviceTitle='Test & Balance of Air & Hydronics'
            serviceDescription='To perform testing, adjusting, and balancing on each system in accordance with AABC standards and procedures. All fans, pumps, and related equipment will be thoroughly tested and balanced to the design specification. A certified report will be issued which includes full workups and all HVAC equipment and air and water distribution. The report shall be a complete record of the HVAC system performance, including conditions of operation, items outstanding, and any deviations found during the test and balance process.'
          />
        ) : pneumatic ? (
          <ShowService
            serviceTitle='Pneumatic Controls & Calibration'
            serviceDescription='A complete balancing of all pressure independent and pressure dependent pneumatic systems. This is to include calibration of all controllers (K3000, K2000 direct acting, K2000 reverse acting, Titus) and thermostats as well as setting up proper staging of pneumatic operated fans and strip heat systems. Strip heat systems will be checked for proper amp draw and discharge temperature. All  systems are set to design flow and air distribution proportioned to design flow. A certified report will be issued including all CFM values and associated equipment along with any deficiencies.'
          />
        ) : deficiency ? (
          <ShowService
            serviceTitle='Deficiency Reports'
            serviceDescription='Included in all ABCO services. Designed to highlight any abnormalities or outstanding items to the customer. Any and all items issued in this report should be resolved and/ or approved and accepted by the owner or design engineer before final acceptance of the project.'
          />
        ) : sound ? (
          <ShowService
            serviceTitle='Sound Testing'
            serviceDescription='Perform sound testing to determine the installed equipments acoustic performance. Calibrated meters are used to test to the project specifications. All readings will be plotted on an octave band chart and used to determine if they are at an acceptable level based on the project specifications. All reading will be included in a certified report.'
          />
        ) : vibration ? (
          <ShowService
            serviceTitle='Vibration Testing'
            serviceDescription='Perform vibration testing to determine if the equipment is within the project and/ or manufactures specification. A calibrated vibration meter is used to thoroughly test the installed equipment. Completed test data will be recorded on the appropriate test form in a certified report.'
          />
        ) : smoke ? (
          <ShowService
            serviceTitle='Smoke Control Testing'
            serviceDescription='Complete testing and balancing of all smoke control systems in accordance to the project specifications. Complete workup of all smoke control fans and associated air distribution including adjusting and balancing stairwell pressurization. A certified report will be issued including all pressures, airflows, and associated equipment.'
          />
        ) : kitchen ? (
          <ShowService
            serviceTitle='Kitchen Hood Certification'
            serviceDescription='A complete test and balance of the installed kitchen hood, exhaust fan, make-up air fan, and associated distribution. Type of hood and filter will be recorded as well as velocity reads for each individual filter. Associated data such as hood dimensions and duct velocity will also be included in the certified hood report.'
          />
        ) : fume ? (
          <ShowService
            serviceTitle='Fume Hood Testing'
            serviceDescription='A complete test and balance of the installed fume hood. Type of hood and all velocity measurements along with calibrations and total CFM will be documented in a certified report. Testing is used to assure the hood will pass third party certification testing when called for.'
          />
        ) : hospital ? (
          <ShowService
            serviceTitle='Hospital Critical Room Certification'
            serviceDescription='Perform testing, adjusting, and balancing of all hospital critical areas. Testing is to include all supply, return, and exhaust inlets and outlets. A certified report will be issued with CFM quantities for each individual outlet as well as air change calculations and pressure relationships. Annual and quarterly testing can be scheduled to insure spaces are staying within tolerance.'
          />
        ) : duct ? (
          <ShowService
            serviceTitle='Duct Pressure Testing'
            serviceDescription='Used to determine the amount of air any section of duct is leaking at a specified pressure. All tests will be conducted according to AABC/ SMACNA standards. Each individual test will be recorded and included in a certified report.'
          />
        ) : cad ? (
          <ShowService
            serviceTitle='CAD / Computer Modeling / Drawings'
            serviceDescription='Replicated construction and/ or survey drawings that are color coded to easily identify systems and individual outlets of a project. Used in conjunction with any air balance report to aid in finding a specific item. All CAD/ drawings are created with the latest in CAD technology.'
          />
        ) : (
          hvac && (
            <ShowService
              serviceTitle='HVAC Surveys & Phased Retrofit Pre-Construction Testing'
              serviceDescription='A thorough examination of all HVAC systems prior to any construction projects.  HVAC surveys are typically used to verify existing conditions and airflows prior to system modifications. This assures the existing system outside of the scope can be returned to its original state. HVAC surveys are also used to show a building’s existing conditions to determine if they are operating at an efficient level. All test data will be recorded and issued in a certified report. This report will include any deficiencies and field observations.'
            />
          )
        )}
      </Div>
    </Div>
  );
};

export default EachService;
