import React from 'react';
import PropTypes from 'prop-types';
import { Div, P } from '../../styled-components';
import ServiceImg from './serviceImg';

const ShowService = ({
  serviceTitle,
  serviceDescription,
  secondText,
  ServiceImage,
}) => (
  <Div
    width='532px'
    width960='300px'
    padding='0 5px 0 10px'
    margin='0 0 0 50px'
    margin960='50px 0 0'
    display='flex'
    flexDirection='column'
  >
    <P margin='0 0 20px' fontSize='22px' fontWeight='bold' color='#666'>
      {serviceTitle}
    </P>
    <ServiceImg ServiceImage={ServiceImage} />
    <P margin='40px 0 10px' fontSize='18px' color='#666'>
      {serviceDescription}
    </P>
    {secondText && (
      <P margin='0' fontSize='18px' color='#666'>
        {secondText}
      </P>
    )}
  </Div>
);

ShowService.propTypes = {
  serviceTitle: PropTypes.string.isRequired,
  serviceDescription: PropTypes.string.isRequired,
  secondText: PropTypes.string,
};

export default ShowService;
