import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralBanner from '../components/generalBanner'
import EachService from '../components/services/eachService'
import Certification from '../components/certification'
import Band from "../components/band"

const AboutUs = () => (
  <Layout>
    <SEO title="Services" />
    <GeneralBanner
      title="SERVICES"
    />
    <EachService />
    <Certification
      background="#f2f2f2"
    />
    <Band
      bandBackground="#23227B"
    />
  </Layout>
)

export default AboutUs
